import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  TextInput,
  SimpleForm,
  SearchInput,
  Filter,
} from 'react-admin';
import {cleanRut, formatRut, required, validateRUT} from "../utils";
import Business from '@material-ui/icons/Business';

export const CompanyIcon = Business;

const CompanyFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="RUT" source="rut.$regex" alwaysOn allowEmpty/>
    <SearchInput label="Name" source="name.$regex" alwaysOn allowEmpty/>
  </Filter>
);

export const CompanyList = props => (
  <List {...props} filters={<CompanyFilter/>}>
    <Datagrid rowClick="edit">
      <TextField source="name"/>
      <TextField source="rut"/>
    </Datagrid>
  </List>
);

export const CompanyEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="name"/>
    </SimpleForm>
  </Edit>
);

export const CompanyCreate = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="name"/>
      <TextInput source="rut" validate={[required(), validateRUT()]} parse={cleanRut} format={formatRut}/>
    </SimpleForm>
  </Edit>
);
