import {stringify} from 'qs';
import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  GET_MANY,
  GET_MANY_REFERENCE,
} from './actions';

import defaultSettings from './default-settings';
import {NotImplementedError} from './errors';
import init from './initializer';
import * as _ from 'lodash';
import {diff} from './../../utils';
import {HttpError} from 'react-admin';
import {toDotNotation} from "../../utils";

// Set HTTP interceptors.
init();

/**
 * Maps react-admin queries to a JSONAPI REST API
 *
 * @param {string} apiUrl the base URL for the JSONAPI
 * @param {function} userSettings Settings to configure this client.
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
const dot = require('dot-object');
export default (type, resource, params) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  let url = '';
  const options = {
    Accept: 'application/json',
    headers: {
      'Content-Type': 'application/json',
    }
  };
  _.merge(options, _.cloneDeep(defaultSettings));
  const token = localStorage.getItem('FIREBASE_TOKEN');
  if (token) _.set(options, 'headers.Authorization', token);

  const mongoPath = (path) => {
    const steps = path.split('.');
    const operatorIds = steps.map((acc, index) => acc[0] === '$' || !Number.isNaN(parseInt(acc)) ? index : undefined).filter(acc => acc !== undefined);
    const newSteps = [steps.slice(0, operatorIds[0] !== undefined ? operatorIds[0] : steps.length).join('.')];
    operatorIds.forEach((operatorId, operatorPosition) => {

    });
    // eslint-disable-next-line no-unused-vars
    for (let operatorPosition in operatorIds) {
      operatorPosition = parseInt(operatorPosition);
      newSteps.push(steps[operatorIds[operatorPosition]]);
      newSteps.push(
        steps.slice(
          operatorIds[operatorPosition] + 1,
          operatorIds[operatorPosition + 1] !== undefined ? operatorIds[operatorPosition + 1] : steps.length
        ).join('.')
      );
    }
    return newSteps.filter(step => step !== '');
  };

  // Add all filter params to query.
  const query = {};
  query['where'] = {};
  const reservedFilters = {'embedded': null, 'projection': null};
  Object.keys(reservedFilters)
    .forEach(rFK => {
        const filterValues = Object.assign({}, _.get(params.filter, rFK, {}), _.get(params, rFK, {}));
        _.set(reservedFilters, rFK, filterValues);
        if (_.get(params, `filter.${rFK}`, false)) delete params.filter[rFK];
      }
    );
  const dottedFilter = dot.dot(params.filter || {});
  Object.keys(dottedFilter || {}).forEach((key) => {
    let value = dottedFilter[key];
    const lastKey = key.split('.').reverse()[0];
    if (lastKey === '$regex')
      value = `(?i)^${value}`;
    _.set(query['where'], mongoPath(key), value);
  });
  if (_.get(query, 'where.$and', false))
    _.set(query, 'where.$and', _.get(query, 'where.$and').filter(a => !_.isNil(a)));
  query['where'] = JSON.stringify(query['where']);
  if (_.get(query, 'where', {}).length === 0)
    delete query['where'];
  Object.keys(reservedFilters)
    .filter(rFK => !!reservedFilters[rFK])
    .forEach(rFK => query[rFK] = JSON.stringify(reservedFilters[rFK]));

  switch (type) {
    case GET_LIST: {
      Object.assign(query, {
        'page': _.get(params, 'pagination.page', 0),
        'max_results': _.get(params, 'pagination.perPage', 50),
      });

      // Add sort parameter
      if (params.sort && params.sort.field) {
        if (params.sort.field === 'iid') params.sort.field = 'id';
        const prefix = params.sort.order === 'ASC' ? '' : '-';
        query.sort = `${prefix}${params.sort.field}`;
      }

      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }

    case GET_ONE:
      if (resource === 'providers') {
        const embedded = JSON.parse(_.get(query, 'embedded', '{}'));
        embedded['machines.category'] = 1;
        embedded['machines.brand'] = 1;
        embedded['machines.model'] = 1;
        query['embedded'] = JSON.stringify(embedded);
      }
      url = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`;
      break;

    case CREATE:
      url = `${apiUrl}/${resource}`;
      options.method = 'POST';
      options.data = JSON.stringify(params.data);
      break;

    case UPDATE: {
      const subpath = _.get(params, 'subpath', false);
      url = `${apiUrl}/${resource}/${params.id}${subpath ? `/${subpath}` : ''}`;
      const data = diff(params.data, params.previousData);
      options.method = options.updateMethod;
      options.data = JSON.stringify(data);
      break;
    }

    case DELETE:
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = 'DELETE';
      break;

    case DELETE_MANY:
      if (params.ids.length === 1) {
        url = `${apiUrl}/${resource}/${params.ids[0]}`;
        options.method = 'DELETE';
      } else {
        // TODO: implement multiple deletions
      }
      break;

    case GET_MANY: {
      const where = `{"_id":{"$in":${JSON.stringify(params.ids)}}}`;
      url = `${apiUrl}/${resource}?${stringify({...query, where})}`;
      break;
    }

    case GET_MANY_REFERENCE: {
      const query = {
        'page': _.get(params, 'pagination.page', 0),
        'max_results': _.get(params, 'pagination.perPage', 50),
      };

      // Add all filter params to query.
      Object.keys(params.filter || {}).forEach((key) => {
        query[`filter[${key}]`] = params.filter[key];
      });

      // Add the reference id to the filter params.
      query[`filter[${params.target}]`] = params.id;

      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }

    default:
      throw new NotImplementedError(`Unsupported Data Provider request type ${type}`);
  }

  return axios({url, ...options})
    .then((response) => {
      switch (type) {
        case GET_MANY:
        case GET_LIST: {
          return {
            data: response.data._items.map(value => Object.assign(
              value,
              {iid: value.id, id: value._id},
            )),
            total: response.data._meta.total,
          };
        }

        case GET_MANY_REFERENCE: {
          return {
            data: response.data._items.map(value => Object.assign(
              value,
              {iid: value.id, id: value._id},
            )),
            total: response.data._meta.total,
          };
        }

        case GET_ONE: {
          const data = {...response.data, id: response.data._id};
          if (response.data.id) data.iid = response.data.id;
          return {data};
        }

        case CREATE: {
          return {
            data: {
              id: response.data._id
            },
          };
        }

        case UPDATE: {
          return {
            data: {
              id: response.data._id
            },
          };
        }

        case DELETE: {
          return {
            data: {id: params._id},
          };
        }

        case DELETE_MANY: {
          if (params.ids.length !== 1)
            throw new NotImplementedError(`Unsupported Data Provider request type ${type}`);
          return {
            data: [{id: params._id}],
          };
        }

        default:
          throw new NotImplementedError(`Unsupported Data Provider request type ${type}`);
      }
    }).catch((error) => {
      let errorMessage, errorCode;
      const message = error.message;
      if (!!message) {
        const {_issues} = message;
        errorMessage = !!_issues ?
          Object.keys(toDotNotation(_issues)).map(key => `${key} ${_.get(_issues, key)}`).join(', ') :
          _.get(message, '0.message._error.message', _.get(message, '_error.message'));
        errorCode = _.get(message, '_error.code', 0);
      } else {
        errorMessage = 'unreachable server';
        errorCode = 0;
      }
      throw new HttpError('Error: ' + errorMessage, errorCode, _.pick(error, _.keys(error)));
    })
};
