import React, {Fragment, useEffect, useState} from 'react';
import compose from 'recompose/compose';
import {
  Datagrid,
  DateField,
  TextField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Edit,
  BooleanInput,
  SelectInput,
  DateInput,
  NumberInput,
  TextInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteArrayInput,
  SearchInput,
  Filter,
  Create,
  FormTab,
  TabbedForm,
  CloneButton,
  CardActions,
  FormDataConsumer,
  Pagination,
  FunctionField,
  useNotify,
  BulkDeleteButton,
} from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import {channels, cleanRut, ColoredChipField, formatRut, required, TabPanel, validateRUT} from "../utils";
import * as _ from 'lodash';
import {PricingTable} from "./machines";
import RichTextInput from 'ra-input-rich-text';
import {withStyles} from '@material-ui/core/styles';
import {createStyles} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GenericFormIterator from "../libs/GenericFormIterator";
import RequestProvidersButton from "./quotes/RequestProvidersButton";
import AddressAutocomplete from "./quotes/AddressAutocomplete";
import DownloadQuoteButton from "./quotes/DownloadQuoteButton";
import SendQuoteByEmailButton from "./quotes/SendQuoteByEmailButton";
import FilterAssetsButton from "./quotes/FilterAssetsButton";
import GenerateQuotationButton from "./quotes/GenerateQuotationButton";
import CustomList from '../libs/CustomList';
import {GET_LIST} from "../ra-data-eve/src/actions";
import AttributesForm from "./assets/AttributesForm";
import * as firebase from "firebase";
import {withDataProvider} from 'react-admin';
import CandidateProvidersTab from "./quotes/CandidateProvidersTab";
import MilestonesInput from "./quotes/MilestonesInput";

export const QuoteIcon = BookIcon;
const dateParser = date => (new Date(`${date}T00:00`)).toUTCString();

const QuoteFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="#" source="id" alwaysOn allowEmpty/>
    <ReferenceInput source="milestones._id" reference="milestones" allowEmpty
                    filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
      <SelectInput label="classificator1" optionText="name"/>
    </ReferenceInput>
    <SearchInput label="Name" source="client.name.$regex" alwaysOn allowEmpty/>
    <SearchInput label="Business Name" source="client.business_name.$regex" alwaysOn allowEmpty/>
    <SelectInput source="seed_channel" choices={channels} allowEmpty/>
  </Filter>
);

const QuotePagination = props => <Pagination rowsPerPageOptions={[50, 100, 500]} {...props} />
const BulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton {...props} undoable={false}/>
  </Fragment>
);
export const QuoteList = props => (
  <CustomList filters={<QuoteFilter/>} pagination={<QuotePagination/>} perPage={50} {...props}
              filterDefaultValues={{embedded: {"services": 1, "machines.category": 1}}}
              sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={<BulkActionButtons/>}>
    <Datagrid rowClick="edit">
      <TextField source="iid" label="#"/>
      <TextField source="client.name"/>
      <TextField source="client.business_name"/>
      <TextField source="client.company_rut"/>
      <TextField source="client.phone"/>
      <TextField source="executive.first_name"/>
      <FunctionField label="Region" render={record => _.get(_.get(record, 'client.place.address_components', []).filter(c => _.get(c, 'types.0') === 'administrative_area_level_1'), '0.long_name')} />
      <ArrayField source="milestones">
        <SingleFieldList>
          <ReferenceField source="_id" reference="milestones">
            <ColoredChipField source="name"/>
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <DateField source="_created"/>
      <ArrayField source="machines" label="Categories">
        <SingleFieldList>
          <ReferenceField source="category._id" reference="categories">
            <ChipField source="name"/>
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <TextField source="admin_notes" label="Notas del administrador"/>
      <DateField source="from_date"/>
      <DateField source="to_date"/>
      <ArrayField source="services">
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ArrayField>
      <FunctionField label="#Providers" render={record => {
        const len = _.get(record, "candidate_providers.length", 0);
        return len > 0 ? len : '';
      }}/>
    </Datagrid>
  </CustomList>
);

const prepareToClone = (data) => {
  if (data) {
    let record = {...data};
    _.set(record, 'services', _.get(record, 'services', []).map(s => s._id || s)
      .filter(({_id}) => _id)
      .map(({_id}) => {
        return {_id: _id._id};
      }));
    return _.omit(record, ['iid', '_id', '_created', '_updated','candidate_providers','candidate_assets','intercom',"send_quote_acknowledgment"])
  }
};
const EditActions = (props) => (
  <CardActions className={props.className} {...props.rest}>
    <SendQuoteByEmailButton
      basePath={props.basePath}
      record={props.data}/>
    {props.data && <RequestProvidersButton {...props} record={props.data}/>}
    <GenerateQuotationButton
      basePath={props.basePath}
      record={props.data}/>
    <DownloadQuoteButton
      basePath={props.basePath}
      record={_.omit(props.data, ['iid', '_id', '_created', '_updated'])}/>
    <CloneButton
      className="button-clone"
      basePath={props.basePath}
      record={prepareToClone(props.data)}
    />
    <FilterAssetsButton
      basePath={props.basePath}
      record={props.data}/>
  </CardActions>
);

function PrevQuoteEdit(props) {
  const [tab, setTab] = useState(0);
  const [settings, setSettings] = useState(undefined);
  const [firebase_users, setFirebase_users] = useState(undefined);
  const notify = useNotify();
  const {dataProvider, classes} = props;

  useEffect(() => {
    if (settings === undefined) dataProvider(GET_LIST, 'settings', {})
      .then(({data}) => setSettings(data[0]))
      .catch(error => notify(error.message, 'warning'));
  }, [dataProvider, notify, settings]);
  useEffect(() => {
    if (firebase_users === undefined) firebase.firestore()
      .collection('users')
      .where("roles.admin", "==", true)
      .get()
      .then((querySnapshot) => {
        const users = [];
        querySnapshot.forEach(doc => users.push(Object.assign({id: doc.id, ...doc.data()})));
        setFirebase_users(users);
      });
  }, [firebase_users]);

  const a11yProps = (index) => {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  };

  const handleChangeChargesTab = (event, tab) => setTab(tab);

    const payment_format = pay => {
      if (pay === undefined){
        return;
      }
      return (pay["id"]);
    }

    const payment_parse = selected => {
      var pay = {};
      console.log(selected);
      settings && settings.payment_methods.forEach(p => {
        const {id, name} = p;
        if (p.id === selected) {
          pay["id"] = id;
          pay["name"] = name;
        }
      });
      return (pay);
    };

    return (
    <Edit undoable={false} actions={<EditActions/>}  {...props}>
      <TabbedForm redirect={false} >
        <FormTab  label="general">
          <DateField source="_created" options={{ hour: 'numeric', minute: 'numeric', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}/>
          <NumberInput label="#" source="iid"/>
          <ReferenceInput source="category" reference="categories">
            <SelectInput optionText="name"/>
          </ReferenceInput>
          <SelectInput choices={firebase_users || []} source="executive"
                       format={v => v && v.id}
                       parse={id => firebase_users && firebase_users.find(r => id === r.id)}
                       optionText={user => Object.values(_.pick(user, ['first_name', 'last_name'])).join(' ')}/>
          <TextInput multiline source="admin_notes" label="Notas del administrador"/>
          <ReferenceArrayInput source="machine_ids" reference="machines" allowEmpty
                               filterToQuery={searchText => searchText && ({embedded: {"category": 1}, name: {"$regex": searchText}})}>
            <AutocompleteArrayInput optionText={option => `${option && option.name} (${_.get(option, 'category.name', '')})`}/>
          </ReferenceArrayInput>
          <MilestonesInput/>
          <DateInput parse={dateParser} validate={[required()]} source="from_date"/>
          <DateInput parse={dateParser} validate={[required()]} source="to_date"/>
          <SelectInput source="seed_channel" choices={channels} allowEmpty/>
          <ReferenceArrayInput reference="services" source="services">
            <SelectArrayInput>
              <ChipField source="name"/>
            </SelectArrayInput>
          </ReferenceArrayInput>
          <div>
            <SelectInput source="payment_method"
                         format = {payment_format}
                         parse = {payment_parse}
                         label="Payment method"
                         optionText="name" optionValue="id"
                         choices={settings && settings.payment_methods}/>
          </div>
          <br>
          </br>
        </FormTab>
        <FormTab label="cliente">
          <TextInput source="client.business_name"/>
          <TextInput source="client.company_rut" validate={[required(), validateRUT()]} parse={cleanRut} format={formatRut}/>
          <TextInput source="client.first_name"/>
          <TextInput source="client.last_name"/>
          <TextInput source="client.email" parse={value => value.toLowerCase()}/>
          <TextInput source="client.phone"/>
          <AddressAutocomplete source="client.address"/>
          <TextInput multiline source="client.comments"/>
        </FormTab>
        <FormTab label="costos">
          <div className={classes.root} >
            <FormDataConsumer>
              {({formData, ...rest}) => {
                return (<AppBar position="static">
                  <Tabs value={tab} onChange={handleChangeChargesTab} {...rest}>
                    {formData && formData.machines && formData.machines.map((value, index) => {
                      return <Tab label={value && value.name} {...a11yProps(index)} />
                    })}
                  </Tabs>
                </AppBar>)
              }}
            </FormDataConsumer>
            <ArrayInput source="machines" fullWidth label="">
              <GenericFormIterator disableRemove={true} disableAdd={true}>
                <FormDataConsumer>
                  {({
                      formData, // The whole form data
                      scopedFormData, // The data for this item of the ArrayInput
                      getSource, // A function to get the valid source inside an ArrayInput
                      ...rest
                    }) => {
                    return scopedFormData && scopedFormData.name ? (
                      <TabPanel value={tab} index={parseInt(rest.id.match(/machines\[(.*?)\]/)[1])}>
                        <ArrayInput source={getSource('charges')} {...rest}>
                          <SimpleFormIterator>
                            <TextInput source="unit"/>
                            <TextInput source="amount"/>
                            <NumberInput source="unit_price"/>
                            <NumberInput source="total"/>
                          </SimpleFormIterator>
                        </ArrayInput>
                      </TabPanel>
                    ) : null
                  }}
                </FormDataConsumer>
              </GenericFormIterator>
            </ArrayInput>
          </div>
        </FormTab>
        <FormTab label="Máquinas">
          <ArrayInput source="machines" fullWidth>
            <SimpleFormIterator>
              <TextInput source="name"/>
              <TextInput source="desc"/>
              <NumberInput source="pricing.transfer.base_fare"/>
              <ArrayInput source="pricing.ranges" fullWidth>
                <SimpleFormIterator>
                  <PricingTable/>
                </SimpleFormIterator>
              </ArrayInput>
              <TextInput source="capacity"/>
              <NumberInput source="year"/>
              <TextInput source="image"/>
              <ReferenceArrayInput reference="services" source="requested_services">
                <SelectArrayInput>
                  <ChipField source="name"/>
                </SelectArrayInput>
              </ReferenceArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Details ">
          <FormDataConsumer>
            {formDataProps =>
              <AttributesForm from_quote={true} fullWidth {...formDataProps}/>
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Términos y Condiciones">
          <ArrayInput source="machines" fullWidth>
            <SimpleFormIterator>
              <RichTextInput source="receipt_copyright"/>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Proveedores">
          <FormDataConsumer>
            {({record}) => <CandidateProvidersTab fullWidth record={record} />}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

export const QuoteEdit = compose(
  withStyles((theme) => createStyles({
      root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
    })
  ),
  withDataProvider,
)(PrevQuoteEdit);


export function QuoteCreate(props) {
  const [firebase_users, setFirebase_users] = useState(undefined);

  useEffect(() => {
    if (firebase_users === undefined) firebase.firestore()
      .collection('users')
      .where("roles.admin", "==", true)
      .get()
      .then((querySnapshot) => {
        const users = [];
        querySnapshot.forEach(doc => users.push(Object.assign({id: doc.id, ...doc.data()})));
        setFirebase_users(users);
      });
  }, [firebase_users]);

  return (
    <Create undoable={false} {...props}>
      <TabbedForm>
        <FormTab redirect={"edit"} label="General">
          <SelectInput choices={firebase_users || []} source="executive"
                       format={v => v && v.id} parse={id => firebase_users && firebase_users.find(r => id === r.id)}
                       optionText={user => Object.values(_.pick(user, ['first_name', 'last_name'])).join(' ')}/>
          <ReferenceInput source="category" reference="categories">
            <SelectInput optionText="name"/>
          </ReferenceInput>
          <TextInput multiline source="admin_notes" label="Notas del administrador"/>
          <ReferenceArrayInput source="machine_ids" reference="machines" allowEmpty
                               filterToQuery={searchText => ({
                                 embedded: {"category": 1},
                                 name: {"$regex": searchText}
                               })}>
            <AutocompleteArrayInput
              optionText={option => `${option && option.name} (${_.get(option, 'category.name', '')})`}/>
          </ReferenceArrayInput>
          <AddressAutocomplete source="client.address"/>
          <DateInput parse={dateParser} validate={[required()]} source="from_date"/>
          <DateInput parse={dateParser} validate={[required()]} source="to_date"/>
          <TextInput source="client.business_name"/>
          <TextInput source="client.company_rut" validate={[required(), validateRUT()]} parse={cleanRut}
                     format={formatRut}/>
          <TextInput source="client.first_name"/>
          <TextInput source="client.last_name"/>
          <TextInput source="client.email" parse={value => value.toLowerCase()}/>
          <TextInput multiline source="client.comments"/>
          <TextInput source="client.phone"/>
          <SelectInput source="seed_channel" choices={channels} allowEmpty/>
          <ReferenceArrayInput reference="services" source="services">
            <SelectArrayInput>
              <ChipField source="name"/>
            </SelectArrayInput>
          </ReferenceArrayInput>
          <BooleanInput source="send_quote_acknowledgment" label="Enviar mail de confirmación" defaultValue={true}/>
        </FormTab>
        <FormTab label="Details ">
          <FormDataConsumer>
            {formDataProps =>
              <AttributesForm from_quote={true} create_quote={true} fullWidth {...formDataProps}/>}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Create>
  );
}
