import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import OfficialProvidersButton from "./OfficialProvidersButton";
import ProviderDataTable from "../assets/ProviderDataTable";
import ProviderTable from "./ProviderTable";
import DeleteProviderButton from "./DeleteProviderButton";
import React, {useState} from "react";
import * as _ from "lodash";
import {TabPanel} from "../../utils";
import {createStyles, withStyles} from "@material-ui/core";
import {compose} from "redux";

function CandidateProvidersTab(props) {
  const [tabProviders, setTabProviders] = useState(0);
  if (!props.record)
    return;
  const record = _.cloneDeep(props.record);
  let candidate_providers = _.cloneDeep(record.candidate_providers);
  if (!candidate_providers || candidate_providers.length === 0)
    return 'No hay proveedores asociados';
  const {classes} = props;

  const handleChangeProvidersTab = (event, tabProviders) => setTabProviders(tabProviders);
  const compareProviders = (a, b) => {
    const aAccepted = _.get(a, 'accepted', undefined), bAccepted = _.get(b, 'accepted', undefined);
    if (aAccepted === bAccepted)
      return 0;
    else if ((aAccepted && !bAccepted) || bAccepted === undefined)
      return -1;
    else if ((!aAccepted && bAccepted) || aAccepted === undefined)
      return 1;
  };
  candidate_providers = candidate_providers.sort(compareProviders).filter(v => !!v._id);
  const scopedFormData = candidate_providers[tabProviders];

  const a11yProps = (index) => {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  };

  return (
    <div className={classes.scroll}>
      <AppBar position="static">
        <Tabs variant="scrollable" scrollButtons="on" value={tabProviders}
              onChange={handleChangeProvidersTab} {...props}>
          {
            candidate_providers.map((value, index) => (
              <Tab style={{backgroundColor: 'accepted' in value ? (value.accepted ? "green" : "red") : ""}}
                   label={value._id.name} value={index} {...a11yProps(index)} />
            ))
          }
        </Tabs>
      </AppBar>
      <TabPanel value={tabProviders} index={tabProviders}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" style={{width: '40px'}}>
                <b>Oficial?</b>
              </TableCell>
              <TableCell align="left">
                <OfficialProvidersButton record={scopedFormData} quote={record}/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" style={{width: '40px'}}>
                <b>Estado</b>
              </TableCell>
              <TableCell
                align="left">{'accepted' in scopedFormData ? (scopedFormData.accepted ? 'Aceptado' : 'Rechazado') : (
                <i>Pendiente de respuesta</i>)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" style={{width: '40px'}}>
                <b>Comentarios</b>
              </TableCell>
              <TableCell align="left">{!scopedFormData.comments ? (
                <i>Sin comentarios</i>) : scopedFormData.comments}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" style={{width: '40px'}}>
                <b>Fecha de confirmación</b>
              </TableCell>
              <TableCell align="left">{!scopedFormData._updated ? (
                <i>Pendiente de respuesta</i>) : scopedFormData._updated}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ProviderDataTable provider={scopedFormData._id} from_quote={true}/>
        <ProviderTable record={scopedFormData} providerId={_.get(scopedFormData, '_id._id', null)} quote={record}/>
        <DeleteProviderButton record={scopedFormData} quote={record}/>
      </TabPanel>
    </div>
  )
}

export default compose(
  withStyles((theme) => createStyles({
      scroll: {
        flexGrow: 1,
        maxWidth: '77vw',
        backgroundColor: theme.palette.background.paper,
      },
    })
  ),
)(CandidateProvidersTab);
