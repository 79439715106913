import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Edit,
  TextInput,
  NumberInput,
  SimpleForm,
  SelectInput,
  Create,
} from 'react-admin';
import Icon from '@material-ui/icons/ListAlt';
import {ColoredChipField, colorStyles} from "../utils";

export const ProviderMilestoneIcon = Icon;

export const ProviderMilestoneList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="key_name"/>
      <TextField source="name"/>
      <NumberField source="priority"/>
      <ColoredChipField source="color"/>
    </Datagrid>
  </List>
);

export const ProviderMilestoneEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="name"/>
      <TextInput source="key_name"/>
      <SelectInput source="color" choices={Object.keys(colorStyles).map(v => {return {id: v, name: v}})} allowEmpty alwaysOn/>
      <NumberInput source="priority"/>
    </SimpleForm>
  </Edit>
);

export const ProviderMilestoneCreate = props => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="name"/>
      <TextInput source="key_name"/>
      <SelectInput source="color" choices={Object.keys(colorStyles).map(v => {return {id: v, name: v}})} allowEmpty alwaysOn/>
      <NumberInput source="priority"/>
    </SimpleForm>
  </Create>
);
