import React from 'react';
import {
  Datagrid,
  TextField,
  ArrayField,
  DateField,
  SingleFieldList,
  ChipField,
  NumberField,
  Edit,
  SelectInput,
  NumberInput,
  TextInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  CheckboxGroupInput,
  ReferenceField,
  Filter,
  SearchInput,
  FormTab,
  TabbedForm,
  Create,
  FunctionField,
  FormDataConsumer,
  CardActions,
  CloneButton,
  Pagination,
  AutocompleteInput,
} from 'react-admin';
import Business from '@material-ui/icons/Business';
import {cleanRut, ColoredChipField, communes, formatRut, regions, validateRUT} from "../utils";
import * as _ from 'lodash';
import {BulkDeleteButton} from 'react-admin';
import AddProvidersToQuoteButton from './providers/AddProvidersToQuoteButton';
import {Fragment} from 'react';
import AssetsTable from "./providers/AssetsTable";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import CustomList from '../libs/CustomList';
import ProviderYapoDataTable from "./providers/ProviderYapoDataTable";

export const ProviderIcon = Business;
let milestones = null;

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <Typography
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ProviderPagination = props => <Pagination rowsPerPageOptions={[50, 100, 500]} {...props} />

const ProviderFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="RUT" source="rut.$regex" alwaysOn allowEmpty/>
    <SearchInput label="Name" source="name.$regex" alwaysOn allowEmpty/>
    <NumberInput label="Phone" source="contacts.phones" alwaysOn allowEmpty/>
    <ReferenceInput source="categories" reference="categories" allowEmpty
                    filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
      <SelectInput label="classificator1" optionText="name"/>
    </ReferenceInput>
    <SelectInput label="Region" source="regions.id" choices={regions} allowEmpty alwaysOn/>
    <ReferenceInput source="milestones._id" reference="provider_milestones" allowEmpty
                    filterToQuery={searchText => ({name: {"$regex": searchText}})} alwaysOn>
      <SelectInput label="classificator1" optionText="name"/>
    </ReferenceInput>
    <NumberInput label="Score" source="score.$gte" alwaysOn/>
  </Filter>
);


const ProviderBulkActionButtons = props => (
  <Fragment>
    <AddProvidersToQuoteButton label="Reset Views" {...props} />
    <BulkDeleteButton {...props} undoable={false}/>
  </Fragment>
);

export const ProviderList = props => (
  <CustomList undoable={0} filters={<ProviderFilter/>} bulkActionButtons={<ProviderBulkActionButtons/>} {...props}
              pagination={<ProviderPagination/>} perPage={50} sort={{ field: '_created', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="rut"/>
      <TextField source="name"/>
      <ReferenceArrayField source="categories" reference="categories">
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ReferenceArrayField>
      <FunctionField label="contact_name" render={record => _.get(record, 'contacts.0.first_name')}/>
      <FunctionField label="email" render={record => _.get(record, 'contacts.0.emails.0')}/>
      <FunctionField label="phone" render={record => _.get(record, 'contacts.0.phones.0')}/>
      <ArrayField source="milestones">
        <SingleFieldList>
          <ReferenceField source="_id" reference="provider_milestones">
            <ColoredChipField source="name"/>
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <NumberField source="qualifies"/>
      <NumberField source="score"/>
      <ArrayField source="regions"><SingleFieldList><ChipField source="name"/></SingleFieldList></ArrayField>
      <DateField source="_created"/>
    </Datagrid>
  </CustomList>
);

const EditActions = ({basePath, className, data, ...rest}) => (
  <CardActions className={className} {...rest}>
    <CloneButton className="button-clone" basePath={basePath}
                 record={_.omit(data, ['iid', '_id', '_created', '_updated', 'rut'])}/>
  </CardActions>
);
export const ProviderEdit = props => {
  const [tab, setTab] = React.useState(0);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  return (
    <Edit undoable={0} {...props} actions={<EditActions/>}
          filterDefaultValues={{embedded: {"machines.category": 1, "machines.brand": 1, "machines.model": 1}}}>
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="name"/>
          <SelectArrayInput choices={regions} optionText="name" optionValue="id" source="regions"
                            format={v => v && v.map(r => r.id)}
                            parse={r_ids => regions.filter(r => r_ids.includes(r.id))}/>
          <AutocompleteInput choices={communes} optionText="name" optionValue="id" source="commune"
                             format={c => _.get(c, 'id')}
                             parse={cId => _.find(communes, ({id}) => id === cId)}/>
          <TextInput source="rut" validate={[validateRUT()]} parse={cleanRut} format={formatRut}/>
          <ReferenceArrayInput reference="categories" source="categories">
            <SelectArrayInput>
              <ChipField source="name"/>
            </SelectArrayInput>
          </ReferenceArrayInput>
          <ReferenceArrayInput source="milestones" reference="provider_milestones" allowEmpty
                               filterToQuery={searchText => ({name: {"$regex": searchText}})}
                               parse={val => val && val.map(v => milestones[v] || {_id: v})}
                               format={val => {
                                 if (milestones === null)
                                   milestones = _.keyBy(val, '_id');
                                 return val && val.map(v => v._id)
                               }}>
            <CheckboxGroupInput/>
          </ReferenceArrayInput>
          <NumberInput source="qualifies"/>
          <TextInput source="source"/>
          <TextInput source="web"/>
        </FormTab>
        <FormTab label="contacts">
          <Fragment>
            <FormDataConsumer>
              {({formData, ...rest}) => {
                return (
                  <AppBar position="static">
                    <Tabs value={tab} onChange={(event, selectedTab) => setTab(selectedTab)} {...rest}>
                      {formData && formData.contacts && formData.contacts.map((value, index) => {
                        const name = `${_.get(value, 'first_name', '')} ${_.get(value, 'last_name', '')}`;
                        return <Tab label={name === ' ' ? `n° ${index + 1}` : name}
                                    {...a11yProps(index)} />
                      })}
                    </Tabs>
                  </AppBar>
                )
              }}
            </FormDataConsumer>
            <ArrayInput source="contacts">
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({scopedFormData, getSource, ...rest}) => {
                    const index = parseInt(rest.id.match(/contacts\[(.*?)\]/)[1]);
                    console.log(tab === index);
                    return tab === index ? (
                      <TabPanel value={tab} index={index}>
                        <TextInput source={getSource("first_name")}/>
                        <TextInput source={getSource("last_name")}/>
                        <ArrayInput source={getSource("phones")}>
                          <SimpleFormIterator><NumberInput/></SimpleFormIterator>
                        </ArrayInput>
                        <ArrayInput source={getSource("emails")}>
                          <SimpleFormIterator><TextInput parse={value => value.toLowerCase()}/></SimpleFormIterator>
                        </ArrayInput>
                      </TabPanel>
                    ) : null
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Fragment>
        </FormTab>
        <FormTab label="assets">
          <AssetsTable/>
        </FormTab>
        <FormTab label="scoring">
          <TextInput source="scoring.ever_leased"/>
          <TextInput source="scoring.responses"/>
          <TextInput source="scoring.pricing"/>
          <TextInput source="scoring.machine_quality"/>
          <TextInput source="scoring.reliability"/>
        </FormTab>
        <FormTab label="Data Sources">
          <Fragment>
            <FormDataConsumer>
              {({formData, ...rest}) => {
                return (
                  <Fragment>
                    <AppBar position="static">
                      <Tabs value={tab} onChange={(event, selectedTab) => setTab(selectedTab)} {...rest}>
                        {formData && formData.data_sources && Object.keys(formData.data_sources).map((value, index) => {
                          return <Tab label={value === ' ' ? `n° ${index + 1}` : value}
                                      {...a11yProps(index)} />
                        })}
                      </Tabs>
                    </AppBar>
                    {
                      (formData && formData.data_sources) && Object.keys(formData.data_sources)[tab] === "yapo" && (<ProviderYapoDataTable fullWidth {...formData}/>)
                    }
                  </Fragment>
                )
              }}
            </FormDataConsumer>
          </Fragment>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const ProviderCreate = props => {
  const [tab, setTab] = React.useState(0);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  return (
    <Create undoable={false} {...props}>
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="name"/>
          <SelectArrayInput choices={regions} optionText="name" optionValue="id" source="regions"
                            format={v => v && v.map(r => r.id)}
                            parse={r_ids => regions.filter(r => r_ids.includes(r.id))}/>
          <AutocompleteInput choices={communes} optionText="name" optionValue="id" source="commune"
                             format={c => _.get(c, 'id')}
                             parse={cId => _.find(communes, ({id}) => id === cId)}/>
          <TextInput source="rut" validate={[validateRUT()]} parse={cleanRut} format={formatRut}/>
          <ReferenceArrayInput reference="categories" source="categories">
            <SelectArrayInput>
              <ChipField source="name"/>
            </SelectArrayInput>
          </ReferenceArrayInput>
          <ReferenceArrayInput source="milestones" reference="provider_milestones" allowEmpty
                               filterToQuery={searchText => ({name: {"$regex": searchText}})}
                               parse={val => val && val.map(v => milestones[v] || {_id: v})}
                               format={val => {
                                 if (milestones === null)
                                   milestones = _.keyBy(val, '_id');
                                 return val && val.map(v => v._id)
                               }}>
            <CheckboxGroupInput/>
          </ReferenceArrayInput>
          <NumberInput source="qualifies"/>
          <TextInput source="source"/>
          <TextInput source="web"/>
        </FormTab>
        <FormTab label="contacts">
          <Fragment>
            <FormDataConsumer>
              {({formData, ...rest}) => {
                return (
                  <AppBar position="static">
                    <Tabs value={tab} onChange={(event, selectedTab) => setTab(selectedTab)} {...rest}>
                      {formData && formData.contacts && formData.contacts.map((value, index) => {
                        return <Tab label={`${_.get(value, 'first_name')} ${_.get(value, 'last_name')}`}
                                    {...a11yProps(index)} />
                      })}
                    </Tabs>
                  </AppBar>
                )
              }}
            </FormDataConsumer>
            <ArrayInput source="contacts">
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({scopedFormData, getSource, ...rest}) => {
                    const index = parseInt(rest.id.match(/contacts\[(.*?)\]/)[1]);
                    console.log(tab === index);
                    return tab === index ? (
                      <TabPanel value={tab} index={index}>
                        <TextInput source={getSource("first_name")}/>
                        <TextInput source={getSource("last_name")}/>
                        <ArrayInput source={getSource("phones")} defaultValue={['']}>
                          <SimpleFormIterator><NumberInput/></SimpleFormIterator>
                        </ArrayInput>
                        <ArrayInput source={getSource("emails")} defaultValue={['']}>
                          <SimpleFormIterator><TextInput parse={value => value.toLowerCase()}/></SimpleFormIterator>
                        </ArrayInput>
                      </TabPanel>
                    ) : null
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Fragment>
        </FormTab>
        <FormTab label="assets">
          <AssetsTable/>
        </FormTab>
        <FormTab label="scoring">
          <TextInput source="scoring.ever_leased"/>
          <TextInput source="scoring.responses"/>
          <TextInput source="scoring.pricing"/>
          <TextInput source="scoring.machine_quality"/>
          <TextInput source="scoring.reliability"/>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
